import React from "react"
import { PageProps, Link } from "gatsby"
import { Container, Row } from "react-bootstrap"

// import Layout from "../components/layout"
// import SEO from "../components/seo"

import './../styles/playlistPage.css'

const AGiftForALionPage = (props: PageProps) => (
    <Container>
        <div>
        <iframe className="agifftforalion-page" allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="1000" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/playlist/pl.u-vxy6974TWovBGZD"></iframe>
        </div>
    </Container>
//   <Layout>
//     <SEO title="agiftforalion" />
//     <Container className="page-frame">
//         <Row className="page-heading">
//         </Row>
//         <Row className="page-body">
//             <body>
//             <iframe src="https://open.spotify.com/embed/playlist/2yT8qZzzUg7fZlYVJEjvk8?utm_source=generator&theme=0" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
//             </body>
//         </Row>
//     </Container>
//   </Layout>
)

export default AGiftForALionPage
